@use "sass:meta" as ---eyygoxs6v1;#ui-timepicker {
  z-index: 2;
}

.form-row {
  ul[data-checkboxtree] {
    ul {
      margin-top: 0 !important;
    }

    label {
      float: none;
      width: auto;
      display: inline;
      margin-top: 2px;
      margin-bottom: 2px;
      padding: 0;
      font-size: 12px;
    }

    input {
      margin: 2px;
    }

    #add_id_rubrica {
      display: none;
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:215");