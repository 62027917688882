@import '~dropzone/dist/dropzone';
@import '~flag-icons-svg/sass/flag-icons';
@import 'assets/styles/core/settings';
@import 'assets/styles/fonts';
@import 'grappelli';
@import 'forms';
@import 'redactor';

#ui-datepicker-div,
#ui-timepicker {
  z-index: 200 !important;
}

div[data-editorjs-holder] {
  max-width: 1000px !important;
}

.codex-editor {
  font-size: $global-font-size;

  .ce-block__content {
    max-width: 900px;
  }

  .ce-toolbar__content {
    max-width: 900px;
  }
}

.cdx-block {
  .image-gallery {
    &__controls {
      padding: 5px 10px;
    }

    .cdx-button {
      height: initial;
      padding: 10px;
    }
  }

  .ce-inline-tool {
    height: initial;
    width: initial;

    &:hover {
      color: initial;
      border: initial;
    }
  }
}

.ce-inline-tool {
  height: initial;
  width: initial;

  &:hover {
    color: initial;
    border: initial;
  }
}
