@use "sass:meta" as ---eyygoxs6v1;.vUUIDField {
    width: 300px !important;
}

.grp-changelist-results {
  table {
    width: 100%;
  }
}

.grp-form-spec {
  .c-1 {
    width: 350px !important;
  }

  .vTextField {
    width: 300px;
  }
}

.grp-row {
  .field-show_analytics {
    text-align: right;
  }

  .field-admin_state_updated_by,
  .field-display_mode,
  .field-price_display,
  .field-state {
    white-space: nowrap;
  }

  .field-title {
    font-weight: $global-weight-bold;
  }

  .field-photo_display {
    text-align: center;

    img {
      max-height: 80px;
      max-width: 120px;
    }
  }

  .field-logo_svg_display {
    width: 120px;
  }

  .field-picture_thumbnail {
    img {
      max-height: 60px;
      max-width: 80px;
    }
  }

  .field-event_display {
    white-space: nowrap;

    span {
      font-weight: bolder;
    }

    a {
      font-weight: bold;
      top: initial !important;
    }
  }

  .field-geo_display {
    .flag-icon {
      vertical-align: top;
    }

    div {
      margin-bottom: .5rem;
      white-space: nowrap;
    }
  }

  .field-etim_class {
    width: 100px;

    input {
      max-width: 100px !important;
    }
  }

  .clearable-image {
    margin-bottom: 1rem;

    label.button {
      display: none;
    }

    &__preview {
      float: left;
      margin-right: 10px;
    }

    &__clear {
      margin-top: 5px;
    }

    &__file {
      display: none;
    }
  }
}

.grp-submit-row {
  &.grp-fixed-footer {
    z-index: 1002;
  }

  .grp-button {
    &.transition-publish,
    &.transition-published {
      background-image: linear-gradient(lighten(#31912e, 10%), #31912e);
    }

    &.transition-reject {
      background-image: linear-gradient(lighten(#912e31, 10%), #912e31);
    }

    &.transition-review {
      background-image: linear-gradient(lighten(#ffc903, 10%), #ffc903);
      color: #222;
    }

    //&.transition-spam {
    //  background-image: linear-gradient(lighten(#3b3b3b, 10%), #3b3b3b);
    //  color: #222222;
    //}

    &.transition-archive,
    &.transition-draft {
      background-image: linear-gradient(lighten(#3b3b3b, 10%), #3b3b3b);
    }

    &.transition-remove {
      background-image: linear-gradient(lighten(#bf3030, 10%), #bf3030);
    }
  }
}

.grp-html-body {
  .grp-readonly {
    font-family: monospace;
    background-color: white;
    max-width: initial;
    cursor: text;
  }

  textarea.vLargeTextField {
    font-family: monospace;
    height: 500px;
    width: 100%;
  }
}

.grp-table tbody tr th {
  text-align: initial;
}

.tabular {
  .grp-td {
    &.newsletter {
      width: 60%;
    }
  }
}

.market-rubrica {
  .form-row {
    textarea {
      width: 80%;
      min-width: 758px;
    }

    #id_meta_title {
      height: 14px * 2;
    }

    #id_meta_description,
    #id_meta_keywords {
      height: 14px * 3;
    }

    #id_description {
      height: 14px * 20;
    }

    .grp-cell.meta_title_updated_by,
    .grp-cell.meta_description_updated_by,
    .grp-cell.meta_keywords_updated_by,
    .grp-cell.description_updated_by {
      .grp-readonly {
        max-width: 500px;

        a {
          color: #444;
          font-weight: normal;
        }
      }
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:214");