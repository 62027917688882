@import 'assets/styles/core';

ul[data-checkboxtree] {
  margin: 0 0 $global-margin;
}

[data-checkboxtree] {
  ul {
    margin: 0;
    padding-left: 20px;
    list-style-type: none;
  }

  li {
    display: block;
    line-height: 20px;
    list-style: none;

    label {
      font-size: $input-font-size;
    }
  }

  li + li {
    margin-left: 0;
  }

  span {
    color: $dark-gray;
    cursor: default;
    font-size: rem-calc(20);
    display: inline-block;
    width: 15px;

    &.expanded,
    &.collapsed {
      cursor: pointer;
    }
  }

  .hide {
    display: none;
  }

  label {
    display: inline;
  }
}
