@use "sass:meta" as ---eyygoxs6v1;.redactor-modal {
  button {
    margin: initial;
    position: initial;
    width: initial;
    height: initial;
    box-sizing: initial;
    overflow: initial;
  }

  &.open {
    left: initial !important;
    margin-left: auto !important;
  }

  .redactor-modal-header {
    padding: 20px 20px 0;
  }
}

.redactor-box {
  background: #fff;

  .redactor-source {
    width: 100%;
    display: none;
  }
}

.redactor-in {
  font-size: $global-font-size;
  max-width: $site-width-xlarge - (20 * 2) - 240;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    background: initial;
    border: initial;
    border-radius: initial;
    text-shadow: initial;
  }

  td {
    border: dotted 2px $dark-gray;
  }

  .column {
    border: dotted 1px $dark-gray;
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:216");